.tlIconLogo {
	height: 50px;
	width: 50px;
}

.navFlex {
	display: flex;
	column-gap: 10px;
	align-items: center;

	> .tlIconLogo {
		margin-left: 10px;
	}
}

.navTitle {
	font-family: TelstraAkkurat-Bold;
}

.container {
	margin: 20px;
}

.layout {
	.footer {
		min-height: 60px;
	}
}

@media (min-width: 768px) {
	.navFlex {
		column-gap: 30px;
		> .tlIconLogo {
			margin-left: 30px;
		}
	}
	.navbar {
		height: 80px;
	}
	.container {
		margin: 40px;
	}
}
